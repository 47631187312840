.player2DWrap {
  position: relative;
  width: 100%;
  height: 100%;
}
.player2DMenu {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
}
.player2DCanvasWrap {
  position: relative;
  width: 100%;
  height: 100%;
  > canvas {
    position: absolute;
    // padding: 0;
    // margin: 0;
    // display: block;
    // font-size: 0;
  }
}
.player2DCanvasBottomControls {
  position: absolute;
  left: 0;
  bottom: 0;
}
.player2DControlsItemWrap {
  position: absolute;
}

@import "./../../../assets/css/variables.scss";

.categoryGroup {
  margin-bottom: 30px;
  max-height: calc(100vh - 36px - 54px - 49px - 78px);
  overflow-y: auto;
  margin-right: -22px;
  padding-right: 18px;
  padding-bottom: 60px;
  @media screen and (max-width: $breakpoint-mob-max) {
    margin-right: -8px;
    padding-right: 3px;
    max-height: calc(35vh - 17px - 35px - 35px);
    padding-bottom: 30px;
    padding-top: 8px;
  }
}

.categoryGroupLabel {
  margin-bottom: 15px;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: #000000;
}

.optionsList {
  display: grid;
  grid-gap: 15px;
  grid-template-columns: repeat(auto-fill, minmax(150px, auto));
  @media screen and (max-width: $breakpoint-IPhone-max) {
    grid-template-columns: repeat(auto-fill, minmax(130px, auto));
  }
}
.iconSelect {
  transition: 0.3s;
}

.iconSelect.rotated {
  transition: 0.3s;
  transform: rotate(180deg);
}

.selectStandart {
  width: 100% !important;
}

.boxSelect {
  position: relative;
  width: 100%;
}

.wrapLabel {
  margin-bottom: 8px;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
}
.option {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  background: #FFFFFF;
  border: 1px solid rgba(66, 69, 72, 0.15);
  border-radius: 2px;
  // cursor: pointer;
  transition: all .2s ease-in-out;
  &.disabled {
    opacity: 0.4;
    cursor: auto;
    pointer-events: none;
    background: #FFFFFF !important;
  }
}

.topContent {
  width: 100%;
}

.optionImg {
  position: relative;
  width: 100%;
  padding-top: 74.92%;
  font-size: 0;
  margin-bottom: 15px;
  .imgWrap {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    text-align: center;
    > img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      &.scaleX {
        display: block;
        max-width: 100%;
        -webkit-transform: scaleX(-1);
        transform: scaleX(-1);
      }
    }
  }
}

.optionLabel {
  margin: 0 0 10px 0;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: #141E34;
  text-align: center;
}

.optionDescription {
  margin: 0 0 10px 0;
  font-weight: 250;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
  text-align: center;
}

.optionSize {
  margin: 0 0 10px 0;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
  text-align: center;
}
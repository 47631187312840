.subMenu2DHelpWrap {
  width: 320px;
  padding: 12px 24px;
  gap: 8px;
  background: #FFFFFF;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 2px;
  .close {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-bottom: 8px;
  }
}

.sliderContent {
  margin-bottom: 20px;
  .title {
    margin-bottom: 18px;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    color: #000000;
  }
  .description {
    margin-bottom: 15px;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #000000;
  }
  .image {
    img {
      min-width: 90%;
      max-width: 100%;
    }
  }
}

.sliderControls {
  display: flex;
  justify-content: flex-end;
}
.modalDecorativeEnd {
  position: relative;
  width: 100%;
  max-width: 350px;
  padding: 32px;
  background-color: #fff;
  .close {
    position: absolute;
    right: 10px;
    top: 10px;
    padding: 6px;
    font-size: 0;
    cursor: pointer;
  }
  .title {
    margin: 0 0 16px 0;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: #000000;
  }
  .descr {
    margin: 0 0 30px 0;
    font-weight: 250;
    font-size: 14px;
    line-height: 17px;
    color: #000000;
  }
  .imgWrap {
    img {
      max-width: 100%;
    }
  }
}
@import "./../../../assets/css/variables.scss";

.sceneControlsBottom3D {
  position: absolute;
  right: 15px;
  bottom: 55px;
  width: 70px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 13px;
  @media screen and (max-width: $breakpoint-mob-max) {
    right: 100px;
    bottom: 10px;
    flex-direction: row;
    width: auto;
    height: 38px;
    align-items: center;
  }
  .sceneControlsBlock {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px;
    gap: 20px;
    background: rgba(255, 255, 255, 0.9);
    border: 1px solid #31344B;
    border-radius: 2px;
    @media screen and (max-width: $breakpoint-mob-max) {
      flex-direction: row;
      height: 100%;
      gap: 5px;
      padding: 5px 5px;
    }
    &:first-child {
      gap: 10px;
      @media screen and (max-width: $breakpoint-mob-max) {
        gap: 5px;
      }
    }
    > button {
      width: auto;
      > svg {
        width: 24px;
        height: auto;
        @media screen and (max-width: $breakpoint-mob-max) {
          width: 20px;
        }
      }
      > span {
        text-transform: capitalize;
        @media screen and (max-width: $breakpoint-mob-max) {
          font-size: 12px;
          line-height: 1;
          white-space: nowrap;
        }
      }
    }
    &.ruler {
      > button {
        @media screen and (max-width: $breakpoint-mob-max) {
          gap: 1px;
          > svg {
            width: 18px;
          }
          > span {
            @media screen and (max-width: $breakpoint-mob-max) {
              font-size: 11px;
            }
          }
        }
      }
    }
  }
}
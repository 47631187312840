.switch {
  position: relative;
  min-width: 190px;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &::before {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    z-index: -20;
    border-radius: 30px;
    background: rgba(255, 255, 255, 0.7);
    backdrop-filter: blur(14px);
    left: 0;
  }
  & .title {
    margin: 0 0 25px 0;
    font-weight: 400;
    font-size: 16px;
    line-height: 16px;
    color: #3f738d;
  }
  & .label {
    display: flex;
    align-items: center;
    margin-right: 30px;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #000000;
    span {
      margin-left: 17px;
      cursor: pointer;
      svg {
        display: block;
      }
    }
  }
}

.radioOptionsWrap {
  margin-bottom: 30px;
}

.radioOptionsLabel {
  margin: 0 0 8px 0;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
}

.radioOptionsList {
  display: grid;
  grid-gap: 15px;
  grid-template-columns: repeat(auto-fill, minmax(100px, 135px));
}
@import "./../../assets/css/variables.scss";

.desktopSectionCabinets {
  position: absolute;
  z-index: 101;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: #ffffff;
  .scrollBlock {
    width: 100%;
    height: 100%;
    padding: 36px 22px 0;
    overflow: hidden;
    @media screen and (max-width: $breakpoint-mob-max) {
      padding: 16px 10px 0;
      font-size: 16px;
      line-height: 19px;
    }
  }
  
  .titleBlock {
    margin-bottom: 30px;
    @media screen and (max-width: $breakpoint-mob-max) {
      margin-bottom: 16px;
    }
  }
  
  .settingsTitle {
    margin: 0;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: #000000;
    @media screen and (max-width: $breakpoint-mob-max) {
      font-size: 16px;
      line-height: 19px;
    }
  }
 
  div[class*="filtersContent"] {
    @media screen and (max-width: $breakpoint-mob-max) {
      height: calc(35vh - 17px - 35px - 35px);
    }
  }
}

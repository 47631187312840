@import "./../../../assets/css/variables.scss";

.categoryGroup {
  margin-bottom: 30px;
}

.categoryGroupLabel {
  margin-bottom: 15px;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: #000000;
  @media screen and (max-width: $breakpoint-mob-max) {
    margin-top: 0;
    font-weight: 400;
  }
}

.optionsList {
  display: grid;
  grid-gap: 15px;
  grid-template-columns: repeat(auto-fill, minmax(150px, auto));
  @media screen and (max-width: $breakpoint-IPhone-max) {
    grid-template-columns: repeat(auto-fill, minmax(130px, auto));
  }
}
@import "./../../../assets/css/variables.scss";

.sceneCabinetControls {
  position: absolute;
  // right: 150px;
  // bottom: 24px;
  
  gap: 10px;
  background: rgba(255, 255, 255, 0.9);
  border-radius: 2px;
  // transform: translateX(-50%);
  // @media screen and (max-width: $breakpoint-mob-max) {
  //   right: 8px;
  //   bottom: 100px;
  // }
  button {
    padding: 5px;
  }
  .head {
    width: auto;
    height: 40px;
    display: flex;
    justify-content: center;
    @media screen and (max-width: $breakpoint-mob-max) {
      height: 35px;
    }
  }
  .footer {
    padding: 4px 8px;
    width: 100%;
    min-width: 140px;
    height: 24px;
    background: rgba(239, 239, 239, 0.9);
    border-radius: 0px 0px 2px 2px;
  }
}

.doorControls {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  @media screen and (max-width: $breakpoint-mob-max) {
    gap: 5px;
  }
}

.dividerCabinetControls {
  width: 2px;
  height: 20px;
  background-color: #332E20;
  border-radius: 2px;
}

.generalButtons {
  width: auto;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 10px;
  gap: 10px;
  background: rgba(255, 255, 255, 0.9);
  border-radius: 2px;
  @media screen and (max-width: $breakpoint-mob-max) {
    padding: 5px;
    gap: 5px;
  }
  button {
    @media screen and (max-width: $breakpoint-mob-max) {
      width: 25px;
      min-height: 25px;
      padding: 3px;
    }
  }
}

.rotateIslands {
  background: rgba(239, 239, 239, 0.9);
  width: auto;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 5px;
  gap: 10px;
  background: #E5E5E5;
  border-radius: 2px;
  [class*="btn"] {
    padding: 2px;
  }
}
@import "./../../assets/css/variables.scss";

.mobHeader {
  display: none;
  grid-area: header;
  align-items: center;
  justify-content: center;
  background-color: var(--brand-color);
  padding: 10px;
  @media screen and (max-width: $breakpoint-mob-max) {
    display: flex;
    height: calc(var(--vh, 1vh) * 8);
    order: 1;
  }
  > img {
    width: auto;
    max-height: 100%;
  }
}

.pageGrid {
  display: grid;
  grid-template-columns: 230px minmax(50%, auto);
  grid-template-rows: 100% 100%;
  grid-template-areas: "sidebar content";
  grid-gap: 0;
  width: 100vw;
  height: calc(var(--vh, 1vh) * 100);
  font-family: "ProximaNova", sans-serif;
  touch-action: none;
  @media screen and (max-width: $breakpoint-mob-max) {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 0;
  }
}

.mainSidebar {
  grid-area: sidebar;
  padding: 20px 20px 16px;
  background-color: var(--brand-color);
  position: relative;
  @media screen and (max-width: $breakpoint-mob-max) {
    overflow: hidden;
    padding: 0;
    order: 3;
    background-color: transparent;
  }
}

.sceneContent {
  position: relative;
  grid-area: content;
  order: 2;
  flex: 1;
}
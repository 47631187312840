.radioOptionsIconTextWrap {
  margin-bottom: 30px;
}

.radioOptionsIconTextLabel {
  margin: 0 0 8px 0;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
}

.radioOptionsIconTextList {
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(auto-fill, minmax(145px, 150px));
}
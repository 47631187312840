@import "./../../assets/css/variables.scss";

.accordionWrap {
  // max-height: calc(100% - 39px - 28px - 19px - 28px - 35px - 28px - 56px);
  max-height: 50vh;
  // overflow: hidden;
  overflow-y: auto;
  @media screen and (max-height: 800px) {
    height: 45vh;
  }
  @media screen and (max-height: 700px) {
    // height: 55vh;
  }
  @media screen and (max-height: 600px) {
    height: 40vh;
  }
  @media screen and (max-height: 500px) {
    height: 35vh;
  }
  @media screen and (max-width: $breakpoint-mob-max) {
    // height: 65vh;
    margin-bottom: 20px;
  }
}

.acordionItemWrap {
  transition: all 0.2s;
  // overflow: hidden;
  // max-height: 75px;
  border-top: 1px solid #BFBFBF;
  
  &:last-child {
    border-bottom: 1px solid #BFBFBF;
  }
  
  // &.active {
  //   height: 100%;
  //   max-height: 385px;
  //   transition: 0.3s;
  //   overflow-y: auto;
  //   // @media screen and (max-width: 1024px) {
  //   //   height: unset;

  //   //   max-height: 10000px !important;
  //   // }
  //   .acordionItem_arrow {
  //     svg {
  //       transform: rotate(0deg);
  //     }
  //   }
  //   // .acordionItem_content {
  //   //   opacity: 1;
  //   //   max-height: calc(100% - 68px);
  //   // }
  // }
}

@keyframes rot {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes rot {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

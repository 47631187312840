// .colorOption {
//   display: flex;
//   justify-content: flex-start;
//   align-items: center;
//   gap: 12px;
//   .color {
//     width: 48px;
//     height: 48px;
//     border: 1px solid rgba(0, 0, 0, 0.125);
//     box-shadow: inset 0px 0px 60px rgba(67, 46, 30, 0.06);
//   }
// }
.selectColor {
  margin-bottom: 30px;
}
.imgOptionsWrap {
  .imgOptionsList {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(40px, 80px));
    column-gap: 16px;
    row-gap: 8px;
  }
  .imgOption {
    padding: 24px 12px;
    background: #FFFFFF;
    border: 1px solid rgba(0, 0, 0, 0.05);
    border-radius: 2px;
    img {
      display: block;
      max-width: 100%;
    }
    &:hover,
    &.active {
      background: #B4BEC5;
    }
  }
}
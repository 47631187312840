.wrapperInputNumber {
  position: relative;
  background: #FFFFFF;
  display: flex;
  align-items: center;
  border: 1px solid #77777B;
  box-shadow: inset 0px 3px 0px rgba(0, 0, 0, 0.045);
  border-radius: 2px;
  .btnInputNumber {
    position: absolute;
    z-index: 2;
    width: 30px;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0.25 !important;
    cursor: pointer;
    &:hover {
      opacity: 0.45 !important;
    }
    &.btnInputNumberDown {
      left: 0;
    }
    &.btnInputNumberUp {
      right: 0;
    }
    &.disabled {
      opacity: 0.075 !important;
    }
  }
  .inputStyle {
    position: relative;
    z-index: 1;
    width: 100%;
    height: 21px;
    // padding-top: 3px;
    background: none;
    border-radius: 2px;
    border: none;
    outline: none;
    box-shadow: none;
    font-family: inherit;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    text-align: center;
    color: #000000;
  }
  .unitVisible {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    padding-left: 1px;
    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;

    font-family: inherit;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    text-align: center;
    color: #000000;
    .unitHiddenValue {
      opacity: 0;
      visibility: hidden;
    }
  }
  .unitHidden {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    height: 100%;
    padding-left: 1px;
    opacity: 0;
    visibility: hidden;
    font-family: inherit;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    text-align: center;
    color: #000000;
  }
}
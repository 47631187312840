@import "./../../assets/css/variables.scss";

.filtersWrap {
  position: relative;
  padding-bottom: 15px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  margin-bottom: 15px;
  @media screen and (max-width: $breakpoint-mob-max) {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    padding-top: 0;
    padding-bottom: 0;
    border-bottom: none;
    margin-bottom: 0;
  }
  .filtersContent {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 16px;
    @media screen and (max-width: $breakpoint-mob-max) {
      display: none;
      grid-template-columns: 1fr;
      align-content: start;

      position: fixed;
      z-index: 101;
      bottom: 0;
      left: 0;
      right: 0;
      height: calc(35vh - 17px - 36px);
      background-color: #fff;
      padding: 1.5vh 10px 0;
      overflow-y: auto;
      gap: 1.5vh;
      &.show {
        display: grid;
      }
    }
  }
  .btnVisibleFilterMob {
    position: absolute;
    right: 0;
    bottom: calc(100% + 8px);
    @media screen and (min-width: $breakpoint-mob-min) {
      display: none;
    }
  }
  .mobileBtns {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 8px;
    margin-top: 1.5vh;
    margin-bottom: 10px;
    @media screen and (min-width: $breakpoint-mob-min) {
      display: none;
    }
  }
}
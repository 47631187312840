@import "./../../assets/css/variables.scss";

.settingsWrap {
  position: relative;
  width: 100%;
  height: 100%;
  max-height: 100%;
  background: #ffffff;
}

.scrollBlock {
  width: 100%;
  height: 100%;
  padding: 36px 22px 0px;
  overflow: hidden;
  @media screen and (max-width: $breakpoint-mob-max) {
    padding: 52px 10px 0px 10px;
  }
  &.active {
    overflow-y: auto;
    padding-bottom: 41px;
    @media screen and (max-width: $breakpoint-mob-max) {
      padding: 16px 10px 10px 10px;
    }
  }
}

.btnCloseSettings {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: 100;
  left: 100%;
  top: 60px;
  width: 23.33px;
  height: 35px;
  padding-right: 4px;
  background: #D9D9D9;
  border-radius: 0px 100px 100px 0px;
  cursor: pointer;
  @media screen and (max-width: $breakpoint-mob-max) {
    left: auto;
    right: 8px;
    top: auto;
    bottom: 0;
    width: 35px;
    height: 23.33px;
    border-radius: 100px 100px 0px 0px;
    padding-right: 0;
    padding-top: 4px;
    z-index: 102;
    svg {
      transform: rotate(-90deg);
    }
  }
}

.titleBlock {
  margin-bottom: 30px;
  @media screen and (max-width: $breakpoint-mob-max) {
    display: none;
  }
}

.settingsTitle {
  margin: 0;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: #000000;
}

.settingsDescription {
  margin: 8px 0 0 0;
  padding-right: 25%;
  font-weight: 250;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
}
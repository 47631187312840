@import "./../../../assets/css/variables.scss";

.modalHelp3D {
  position: relative;
  width: 100%;
  max-width: 950px;
  max-height: 90vh;
  padding: 60px 20px;
  background-color: #fff;
  @media screen and (max-height: 700px) {
    padding: 30px 20px;
  }
  @media screen and (max-width: $breakpoint-mob-max) {
    max-height: calc(100vh - 50px);
    padding: 25px 10px;
  }

  .content {
    width: 100%;
    height: 100%;
    max-width: 680px;
    margin: 0 auto;
  }

  .title {
    margin: 0 0 28px 0;
    font-weight: 700;
    font-size: 32px;
    line-height: 39px;
    color: #000000;
    text-align: center;
    @media screen and (max-width: $breakpoint-mob-max) {
      font-size: 24px;
      line-height: 28px;
      margin: 0 0 15px 0;
    }
    @media screen and (max-width: 350px) {
      font-size: 22px;
      line-height: 26px;
      margin: 0 0 20px 0;
    }
  }
  .headSubTitle {
    margin: 0 0 28px 0;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    color: #000000;
    text-align: center;
    @media screen and (max-width: $breakpoint-mob-max) {
      font-size: 14px;
      margin: 0 0 15px 0;
    }
  }
  .footerDescr {
    margin: 0 0 28px 0;
    padding: 16px 30px 0;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    color: #000000;
    text-align: center;
    @media screen and (max-width: $breakpoint-mob-max) {
      margin: 0 0 16px 0;
      padding: 0;
    }
    @media screen and (max-width: $breakpoint-IPhone-max) {
      font-size: 14px;
      line-height: 17px;
    }
  }
  .btnsWrap {
    display: flex;
    justify-content: center;
  }
}
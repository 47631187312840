@import "./../../../assets/css/variables.scss";

.sceneInfoText {
  position: absolute;
  right: 100px;
  bottom: 24px;
  padding-left: 40px;
  width: auto;
  max-width: 50%;
  @media (max-width: 1350px) {
    padding-left: 20px;
    max-width: calc(100% - 437px - 100px);
  }
  @media screen and (max-width: $breakpoint-mob-max) {
    left: 5px;
    padding-left: 0;
    bottom: 5px;
    max-width: calc(100% - 240px - 100px);
  }
  @media screen and (max-width: 600px) {
    left: 5px;
    right: 5px;
    padding-left: 0;
    bottom: 55px;
    max-width: 100%;
  }
  p {
    margin: 0;
    padding: 0;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #000000;
    @media screen and (max-width: $breakpoint-mob-max) {
      font-size: 11px;
      line-height: 1.1;
      text-align: center;
    }
  }

  // &.active {
  //   left: 437px;
  // }
 
}
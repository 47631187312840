.player_wrapper{
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
    touch-action: none;
    div[class*="help"] {
        z-index: 1;
    }
    background-color: #fff;
    :global {
        .tk-player {
            min-height: 100px;
            > div:nth-child(1) {
                min-height: 100px;
            }
        }
    }
}
.player{
    height: 100%;
    width: 100%;
    div[class^="help"] {
        z-index: 11;
    }
    @media screen and (max-width: 650px) {
        user-select: none;
    }
}
.threekit {
    z-index: 11;
}

@media screen and (max-width:992px) {
    .player{
        height: 100%;
        width: 100%;
    } 
}
@import "./../../assets/css/variables.scss";

.productsGroup {
  margin-bottom: 30px;
  max-height: calc(100vh - 36px - 54px - 49px - 78px);
  overflow-y: auto;
  margin-right: -22px;
  padding-right: 18px;
  padding-bottom: 60px;
  @media screen and (max-width: $breakpoint-mob-max) {
    margin-right: -8px;
    padding-right: 3px;
    max-height: calc(35vh - 17px - 35px - 35px);
    padding-bottom: 30px;
    padding-top: 8px;
  }
  div[class*="categoryGroup"] {
    max-height: none;
    overflow-y: visible;
    margin-right: 0;
    padding-right: 0;
    padding-bottom: 0;
  }
}
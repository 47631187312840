@import "./../../../assets/css/variables.scss";

.acordionItem {
  width: 100%;
  // height: 100%;
  // transition: all 0.2s;
  // overflow: hidden;
  // max-height: 75px;
  // border-bottom: 1px solid rgba(61, 115, 141, 0.5);
  // @media screen and (max-width: 992px) {
  //     max-height: none;
  // }
  // &:last-child {
  //     border-bottom: none;
  //     .acordionItem_head {
  //         border-bottom: 1px solid rgba(61, 115, 141, 0.5);
  //     }
  //     &.active {
  //         .acordionItem_head {
  //             border-bottom: none;
  //         }
  //         .acordionItem_content {
  //             border-bottom: 1px solid rgba(61, 115, 141, 0.5);
  //         }
  //     }
  // }

  &_head {
    position: relative;
    padding: 10px 70px 10px 32px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 68px;
    // height: 6.3vh;
    cursor: pointer;
    @media screen and (max-width: $breakpoint-mob-max) {
      height: 56px;
      padding: 5px 45px 5px 16px;
    }
    @media screen and (max-width: $breakpoint-IPhone-max) {
      height: 50px;
    }
  }

  &_title {
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    color: #000000;
    @media screen and (max-width: $breakpoint-IPhone-max) {
      font-size: 14px;
      line-height: 17px;
    }
  }

  &_arrow {
    position: absolute;
    right: 41px;
    top: 50%;
    transform: translateY(-50%);
    @media screen and (max-width: $breakpoint-mob-max) {
      right: 16px;
    }
    svg {
      transition: all 0.2s;
      transform: rotate(180deg);
    }
  }
  &.active {
    // height: 100%;
    // max-height: 100%;
    // transition: 0.3s;
    // @media screen and (max-width: 1024px) {
    //     height: auto;
    //     max-height: none;
    // }
    .acordionItem_arrow {
      svg {
        transform: rotate(0deg);
      }
    }
    // .acordionItem_content {
    //   opacity: 1;
    //   max-height: calc(100% - 68px);
    //   @media screen and (max-width: $breakpoint-mob-max) {
    //     max-height: calc(100% - 56px);
    //   }
    //   // @media screen and (max-width: 1024px) {
    //   //   max-height: none;
    //   // }
    // }
  }
  &_content {
    padding: 0 32px;
    // transition: opacity 0.3s;
    // opacity: 0;
    // overflow: auto;
    // max-height: 0;
    // overflow-x: hidden;
    box-sizing: border-box;
    @media screen and (max-width: $breakpoint-mob-max) {
      padding: 0 16px;
    }
    // @media screen and (max-width: 1024px) {
    //   padding: 0 20px;
    // }
    // @media screen and (max-width: 350px) {
    //   padding: 0 15px;
    // }
  }
  &_contentInternal {
    padding-bottom: 16px;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #000000;
    @media screen and (max-width: $breakpoint-IPhone-max) {
      font-size: 12px;
      line-height: 16px;
    }
    > * {
      margin: 0 0 16px 0;
      &:last-child {
        margin-bottom: 0;
      }
      p, ul, ol {
        margin: 0;
      }
    }
    p {
      img {
        vertical-align: middle;
      }
    }
    ol, ul {
      padding-left: 20px;
      img {
        vertical-align: middle;
      }
    }
    [class="icon"] {
      margin-bottom: 10px;
      display: flex;
      align-items: center;
      > img {
        margin-right: 8px;
      }
    }
  }
  
}

@import "./../../assets/css/variables.scss";

.pageWrap {
  position: relative;
  width: 100vw;
  height: calc(var(--vh, 1vh) * 100);
  padding: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "ProximaNova", sans-serif;
  @media screen and (max-width: $breakpoint-mob-max) {
    padding: 20px;
  }
  &::before {
    content: "";
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: #fff;
    background-image: url("../../assets/room-list/start-page-bg.png");
    background-position: center;
    background-size: cover;
    filter: blur(2px);
  }
}
.container {
  width: 100%;
  max-width: 1400px;
}
.titleBlock {
  width: 100%;
  max-width: 350px;
  margin: 0 auto 6vh;
  text-align: center;
  @media screen and (max-width: $breakpoint-mob-max) {
    margin: 0 auto 3vh;
  }
  img {
    width: 100%;
    // height: 22.13vh;
    margin-bottom: 3vh;
    vertical-align: top;
    @media screen and (max-width: $breakpoint-mob-max) {
      height: 11vh;
      width: auto;
    }
  }
  h1 {
    margin: 0;
    font-size: 32px;
    line-height: 39px;
    text-align: center;
    color: #171e35;
    font-weight: 700;
    @media screen and (max-width: $breakpoint-mob-max) {
      font-size: 28px;
      line-height: 34px;
    }
    @media screen and (max-width: $breakpoint-IPhone-max) {
      font-size: 22px;
      line-height: 24px;
    }
  }
}

.iconSelect {
    transition: 0.3s;
}

.iconSelect.rotated {
    transition: 0.3s;
    transform: rotate(180deg);
}

.selectStandart {
    width: 100% !important;

    @import '~antd/dist/antd.css';
}

.optionDisable {
    opacity: 0.5;
    pointer-events: none;
}
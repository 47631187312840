@import "./../../../assets/css/variables.scss";

.tabsWrap {
  display: block;
  @media screen and (max-width: $breakpoint-mob-max) {
    margin-top: -35px;
  }
}
.tabsLinkList {
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  margin: 0 0 30px 0;
  list-style: none;
  padding: 0;
  @media screen and (max-width: $breakpoint-mob-max) {
    margin-bottom: 16px;
  }
}
.tabsLink {
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: #141E34;
  cursor: pointer;
  margin-right: 30px;
  position: relative;
  &:last-child {
    margin-right: 0;
  }
  &::before {
    content: '';
    position: absolute;
    width: 0;
    height: 2px;
    bottom: -2px;
    left: 50%;
    transform: translateX(-50%);
    background: #000000;
    transition: width .1s ease-in-out;
  }
  &.active {
    &::before {
      width: 100%;
    }
  }
}

.tabsContent {
  display: block;
  div[class*="categoriesGroup"] {
    max-height: calc(100vh - 36px - 49px - 54px - 78px);
    overflow-y: auto;
    @media screen and (max-width: $breakpoint-mob-max) {
      max-height: calc(35vh - 16px - 36px);
    }
  }
}
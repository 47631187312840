body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
html, body {
  margin: 0;
  padding: 0;
  width: 100%;
  max-width: 100%;
  overflow-x: hidden;
}

body *::-webkit-scrollbar-track {
  background: none; /* цвет фона у дорожки */
  box-shadow: none; /* тень у дорожки */
  border-radius: 9em;
}
/* полоса прокрутки (скроллбар) */
body *::-webkit-scrollbar {
  width: 5px;
  /* ширина для вертикального скролла */
  height: 5px;
  /* высота для горизонтального скролла */
  background-color: transparent;
}
/* ползунок скроллбара */
body *::-webkit-scrollbar-thumb {
  transition: all 0.2s;
  cursor: pointer;
  background: #FFFFFF;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 2px;
  cursor: pointer;
}
body *::-webkit-scrollbar-thumb:hover {
  background-color: rgb(207, 211, 216, 1);
}

div,
span,
p,
h1,
h2,
h3,
h4,
a,
label,
input {
  box-sizing: border-box;
}




.customARClass {
  display: none !important;
}




.point {
  position: absolute;
  width: 54px;
  height: 54px;
  background-color: #EE4675;
  border-radius: 50% !important;
  -moz-border-radius: 50% !important;
  -webkit-border-radius: 50% !important;
  transform: translate(-50%, -50%);
  color: #fff;
  cursor: pointer;
  outline: solid 4px transparent;
  outline-offset: 30px;
}

.point::after {
  content: "+";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 3rem;
}

.point.active {
  -webkit-box-shadow: 0px 0px 45px 8px rgba(179, 202, 218, 1);
  -moz-box-shadow: 0px 0px 45px 8px rgba(179, 202, 218, 1);
  box-shadow: 0px 0px 45px 8px rgba(179, 202, 218, 1);
}

.point.active::before {
  content: "";
  position: absolute;
  top: -3px;
  right: -3px;
  bottom: -3px;
  left: -3px;
  border: 2px solid #00699a;
  border-radius: 50%;
  animation: animationPointActive 1s cubic-bezier(0.4, 0, 1, 1) infinite !important;
  -webkit-animation: animationPointActive 1s cubic-bezier(0.4, 0, 1, 1) infinite !important;
  -moz-animation: animationPointActive 1s cubic-bezier(0.4, 0, 1, 1) infinite !important;
  -o-animation: animationPointActive 1s cubic-bezier(0.4, 0, 1, 1) infinite !important;
}

.point.click {
  border: solid 2px rgba(179, 202, 218, 1);
  -webkit-box-shadow: 0px 0px 45px 8px rgba(179, 202, 218, 1);
  -moz-box-shadow: 0px 0px 45px 8px rgba(179, 202, 218, 1);
  box-shadow: 0px 0px 45px 8px rgba(179, 202, 218, 1);
}

@media (max-width: 1024px) {
  .point {
    width: 21px;
    height: 21px;
  }

  .point::after {
    top: 44%;
    font-size: 1.3rem;
  }
}

/*---ANIMATIONS----*/
@keyframes animationPointActive {
  0% {
    top: -30px;
    right: -30px;
    bottom: -30px;
    left: -30px;
  }
  50% {
    top: 1px;
    right: 1px;
    bottom: 1px;
    left: 1px;
  }

  100% {
    top: -30px;
    right: -30px;
    bottom: -30px;
    left: -30px;
  }
}

@-webkit-keyframes animationPointActive {
  0% {
    top: -30px;
    right: -30px;
    bottom: -30px;
    left: -30px;
  }
  50% {
    top: 1px;
    right: 1px;
    bottom: 1px;
    left: 1px;
  }

  100% {
    top: -30px;
    right: -30px;
    bottom: -30px;
    left: -30px;
  }
}
@-moz-keyframes animationPointActive {
  0% {
    top: -30px;
    right: -30px;
    bottom: -30px;
    left: -30px;
  }
  50% {
    top: 1px;
    right: 1px;
    bottom: 1px;
    left: 1px;
  }

  100% {
    top: -30px;
    right: -30px;
    bottom: -30px;
    left: -30px;
  }
}
@-o-keyframes animationPointActive {
  0% {
    top: -30px;
    right: -30px;
    bottom: -30px;
    left: -30px;
  }
  50% {
    top: 1px;
    right: 1px;
    bottom: 1px;
    left: 1px;
  }

  100% {
    top: -30px;
    right: -30px;
    bottom: -30px;
    left: -30px;
  }
}
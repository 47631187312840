@import "./../../../assets/css/variables.scss";

.modalPrice {
  position: relative;
  width: 100%;
  max-width: 1220px;
  height: 87vh;
  padding: 38px 55px 38px 30px;
  background-color: #fff;
  @media screen and (max-width: $breakpoint-xl-max) {
    padding: 25px 50px 25px 16px;
  }
  @media screen and (max-width: $breakpoint-mob-max) {
    height: calc(100vh - 50px);
    padding: 0;
  }

  .content {
    position: relative;
    display: flex;
    height: 100%;
    max-height: 100%;
  }

  .contentProducts {
    flex: 1 0;
    padding: 0 0 0 30px;
    margin-right: 15px;
    @media screen and (max-width: $breakpoint-xl-max) {
      padding: 0;
    }
    @media screen and (max-width: $breakpoint-mob-max) {
      padding: 30px 15px 20px;
      width: 100%;
      margin-right: 0;
    }
  }

  .contentQuote {
    position: relative;
    width: 370px;
    padding: 16px 16px 0 16px;
    border-left: 1px solid rgba(0, 0, 0, 0.1);
    @media screen and (max-width: $breakpoint-mob-max) {
      position: absolute;
      width: 100%;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: #fff;
      padding-bottom: 0;
    }
    [class*="radioOptionsList"] {
      display: grid;
      grid-gap: 15px;
      grid-template-columns: repeat(auto-fill, minmax(100px, 100px));
      [class*="radioOption_"] {
        padding: 16px 8px;
        justify-content: flex-start;
      }
      [class*="radioOptionImg"] {
        padding-top: 92%;
      }
    }
    .totalSumm, .totalDescr {
      @media screen and (max-width: $breakpoint-mob-max) {
        display: none;;
      }
    }
    .scrolledContentMob {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: calc(100% - 50px);
      overflow-y: auto;


      // max-height: calc(100% - 44px);
      margin-right: -15px;
      padding-right: 15px;
      padding-bottom: 20px;
      @media screen and (max-width: $breakpoint-mob-max) {
        overflow-y: auto;
      }
      div[class*="radioOptionsWrap"] {
        margin-bottom: 16px;
      }
      *, *:before, *:after {
        min-width: auto;
        min-height: auto;
      }
    }
  }

  .title {
    margin: 0 0 20px 0;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    color: #000000;
    text-align: center;
    @media screen and (max-width: $breakpoint-IPhone-max) {
      margin-bottom: 15px;
    }
  }

  .close {
    position: absolute;
    top: 0;
    left: 100%;
    width: 36px;
    height: 36px;
    padding: 5px;
    cursor: pointer;
    opacity: .85;
    @media screen and (max-width: $breakpoint-mob-max) {
      top: 5px;
      right: 5px;
      left: auto;
      width: 25px;
      height: 25px;
    }
    &:hover {
      opacity: 1;
    }
    svg {
      width: auto;
      height: 100%;
    }
  }
}

.summaryProductsTabs {
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  margin: 0 0 30px 0;
  list-style: none;
  padding: 0;
  @media screen and (min-width: $breakpoint-mob-min) {
    display: none;
  }
  @media screen and (max-width: $breakpoint-IPhone-max) {
    margin-bottom: 15px;
  }
  .tabLink {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #141E34;
    cursor: pointer;
    margin-right: 30px;
    position: relative;
    &:last-child {
      margin-right: 0;
    }
    &::before {
      content: '';
      position: absolute;
      width: 0;
      height: 2px;
      bottom: -2px;
      left: 50%;
      transform: translateX(-50%);
      background: #000000;
      transition: width .1s ease-in-out;
    }
    &.active {
      &::before {
        width: 100%;
      }
    }
  }
}

.scrollBlock {
  height: calc(100% - 50px);
  padding-right: 15px;
  overflow: hidden;
  overflow-y: auto;
  @media screen and (max-width: $breakpoint-mob-max) {
    padding-right: 0;
    height: calc(100% - 49px - 49px - 200px);
    margin-right: -10px;
    padding-right: 10px;
  }
  @media screen and (max-width: $breakpoint-IPhone-max) {
    height: calc(100% - 44px - 34px - 200px);
  }
}

.mobSummPrice {
  position: relative;
  padding-top: 16px;
  @media screen and (min-width: $breakpoint-mob-min) {
    display: none;
  }
  &::before {
    content: '';
    position: absolute;
    height: 2px;
    left: -15px;
    right: -15px;
    top: 0;
    background: rgba(0, 0, 0, 0.1)
  }
  .totalSumm {
    margin-bottom: 16px;
    text-align: center;
  }
  .btnWrap {
    width: 100%;
    max-width: 350px;
    margin: 0 auto 16px auto;
  }
}

.totalSumm {
  margin-bottom: 30px;
  font-weight: 700;
  font-size: 24px;
  line-height: 20px;
  color: rgba(0, 0, 0, 0.85);
  @media screen and (max-width: $breakpoint-IPhone-max) {
    font-size: 20px;
  }
}

.totalDescr {
  margin: 0 0 32px 0;
  font-weight: 250;
  font-size: 14px;
  line-height: 17px;
  color: rgba(0, 0, 0, 0.85);
  @media screen and (max-width: $breakpoint-IPhone-max) {
    font-size: 12px;
  }
  .totalDescrTitle {
    margin: 0;
    font-weight: 400;
    color: #000000;
  }
}

.closeQuoteMob {
  position: absolute;
  top: 5px;
  right: 5px;
  width: 25px;
  height: 25px;
  padding: 5px;
  cursor: pointer;
  opacity: .85;
  @media screen and (min-width: $breakpoint-mob-min) {
    display: none;
  }
  &:hover {
    opacity: 1;
  }
  svg {
    width: auto;
    height: 100%;
  }
}

.disclaimer {
  // position: absolute;
  // left: 16px;
  // bottom: 0;
  @media screen and (max-width: $breakpoint-mob-max) {
    position: static;
  }
  h4 {
    margin: 0;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
  }
  p {
    margin: 0;
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 14px;
    a {
      color: inherit;
    }
  }
}

.scrollTableBlock {
  height: 100%;
  padding-right: 15px;
  overflow: hidden;
  overflow-y: auto;
  @media screen and (max-width: $breakpoint-mob-max) {
    padding-right: 0;
    height: calc(100% - 200px);
    margin-right: -10px;
    padding-right: 3px;
  }
  @media screen and (max-width: $breakpoint-IPhone-max) {
    height: calc(100% - 44px - 34px - 200px);
  }
}
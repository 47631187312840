.pageTestGrid {
  display: flex;
  width: 100vw;
  height: 100vh;
}
.mainTestSidebar {
  width: 230px;
  background-color: lightgreen;
  height: 100%;
}
.sceneTestContent {
  width: calc(100% - 230px);
  height: 100%;
  > canvas {
    display: block;
  }
}
.boxSelectColor {
  margin-bottom: 30px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.selectColorWrapLabel {
  margin-bottom: 16px;
  .label {
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    color: #000000;
  }
}
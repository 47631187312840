@import "./../../../assets/css/variables.scss";

.modalsWrap {
  position: fixed;
  z-index: 1001;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 25px 16px;
  background-color: rgba(0,0,0,.1);

  display: flex;
  align-items: center;
  justify-content: center;
  
  font-family: "ProximaNova", sans-serif;
}
.radioOption {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 16px;
  background: #FFFFFF;
  border: 1px solid rgba(66, 69, 72, 0.1); 
  border-radius: 2px;
  cursor: pointer;
  transition: all .2s ease-in-out;
  &.active {
    border: 1px solid #1F2944;
    box-shadow: inset 0px 0px 0px 1px #1F2944;
    .checkedIndicator {
      opacity: 1;
    }
  }
}

.radioOptionImg {
  position: relative;
  width: 100%;
  padding-top: 113%;
  font-size: 0;
  margin-bottom: 15px;
  .imgWrap {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    text-align: center;
    > img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}

.radioOptionLabel {
  margin: 0;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #141E34;
  text-align: center;
}

.radioOptionDescription {
  margin: 5px 0 0 0;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.1;
  color: #141E34;
  text-align: center;
}

.checkedIndicator {
  position: absolute;
  top: 2px;
  right: 2px;
  opacity: 0;
  transition: opacity .2s ease-in-out;
}
@import "./../../assets/css/variables.scss";

.categoriesGroup {
  display: block;
  max-height: calc(100vh - 36px - 54px - 78px);
  overflow-y: auto;
  margin-right: -22px;
  padding-right: 18px;
  padding-bottom: 30px;
  @media screen and (max-width: $breakpoint-mob-max) {
    margin-right: -8px;
    padding-right: 3px;
    max-height: calc(35vh - 16px - 36px);
    padding-bottom: 0;
    padding-top: 16px;
  }
}
@import "./../../../assets/css/variables.scss";

.sceneControlsTop {
  position: absolute;
  top: 27px;
  right: 27px;
  display: flex;
  align-items: center;
  @media screen and (max-width: $breakpoint-mob-max) {
    top: 12px;
    right: 8px;
    left: 8px;
    justify-content: flex-end;
  }
  > * {
    margin-right: 16px;
    &:first-child {
      margin-right: 26px;
      @media screen and (max-width: $breakpoint-mob-max) {
        margin-right: auto;
      }
    }
    &:last-child {
      margin-right: 0;
    }
  }
}
@import "./../../../assets/css/variables.scss";

.modalMessage {
  position: relative;
  z-index: 100000;
  width: 100%;
  max-width: 944px;
  padding: 63px 135px;
  background-color: #fff;
  @media screen and (max-width: $breakpoint-mob-max) {
    max-width: 600px;
    padding: 20px 25px;
  }
  .title {
    margin: 0 0 32px 0;
    font-weight: 700;
    font-size: 32px;
    line-height: 1.2;
    color: #000000;
    text-align: center;
    @media screen and (max-width: $breakpoint-mob-max) {
      margin-bottom: 20px;
      font-size: 18px;
    }
  }
  .btnsWrap {
    display: flex;
    align-items: flex-start;
    gap: 16px;
    width: 270px;
    margin:  0 auto;
    @media screen and (max-width: $breakpoint-mob-max) {
      width: 200px;
      justify-content: center;
    }
  }
}
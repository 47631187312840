@import "./../../assets/css/variables.scss";

.mainMenuWrap {
  position: relative;
  z-index: 102;
  display: flex;
  flex-direction: column;
  justify-content: center;
  &_2D {
    @media screen and (max-width: $breakpoint-mob-max) {
      height: 100%;
      max-height: 8vh;
    }
    .mainMenu {
      padding: 0 0 0 5px;
      @media screen and (max-width: $breakpoint-mob-max) {
        padding: 0;
      }
    }
    .mainMenuLink {
      > img {
        flex: 18px 0 0;
        max-width: 18px;
        max-height: 18px;
      }
    }
  }
}
.mainMenuLogoWrap {
  margin-bottom: 50px;
  @media screen and (max-width: $breakpoint-mob-max) {
    display: none;
  }
}
.mainMenu {
  @media screen and (max-width: $breakpoint-mob-max) {
    position: relative;
    height: 100%;
    padding: 0;
  }
}
.mainMenuScroll {
  padding: 0 10px;
  @media screen and (max-width: $breakpoint-mob-max) {
    display: flex;
    align-items: center;
    gap: 16px;
    padding: 6px 10px 9px 10px;
    overflow: visible;
    overflow-x: auto;
    overflow-y: visible;
    border-bottom: 3px solid #d9d9d9;
    height: 100%;
    background-color: var(--brand-color);
    &::after {
      content: "";
      position: absolute;
      z-index: 10000;
      height: 3px;
      width: 100%;
      bottom: 6px;
      left: 0;
      background: #d9d9d9;
    }
    &::-webkit-scrollbar-track {
      background: #d9d9d9; /* цвет фона у дорожки */
      box-shadow: none; /* тень у дорожки */
      border-radius: 0;
    }
    &::-webkit-scrollbar-button {
      background: #d9d9d9;
      width: 4px;
    }
    /* полоса прокрутки (скроллбар) */
    &::-webkit-scrollbar {
      width: 5px;
      /* ширина для вертикального скролла */
      height: 3px;
      /* высота для горизонтального скролла */
      background-color: transparent;
    }
    /* ползунок скроллбара */
    &::-webkit-scrollbar-thumb {
      transition: all 0.2s;
      cursor: pointer;
      background: #000000;
      border: none;
      border-radius: 4px;
    }
    &::-webkit-scrollbar-thumb:hover {
      background: #000000;
    }
  }
}
.mainMenuLink {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
  padding: 8px;
  gap: 0;
  border-radius: 2px;
  margin-bottom: 23px;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  @media screen and (min-width: $breakpoint-mob-min) {
    &:hover {
      background: rgba(255, 255, 255, 0.2);
    }
  }
  @media screen and (max-width: $breakpoint-mob-max) {
    position: static;
    height: 100%;
    margin: 0;
    white-space: nowrap;
    font-size: 14px;
    gap: 10px;
    // &::before {
    //   content: "";
    //   position: absolute;
    //   height: 3px;
    //   width: calc(100% + 16px);
    //   bottom: -9px;
    //   left: -8px;
    //   background: #d9d9d9;
    // }
  }
  &.active {
    background: rgba(255, 255, 255, 0.2);
  }
  &.selectBtn {
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='2' ry='2' stroke='white' stroke-width='2' stroke-dasharray='10%2c 9' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
    border-radius: 2px;
  }
  img {
    flex: 40px 0 0;
  }
  .mainMenuLinktext {
    flex: auto 1 1;
    text-transform: capitalize;
    margin: 11px 8px 11px 16px;
    white-space: nowrap;
    @media screen and (max-width: $breakpoint-mob-max) {
      margin: 0;
    }
  }
  .editMode {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0;
  }
}

.menuItemSubContent {
  position: absolute;
  z-index: 101;
  top: 0;
  left: 100%;
  @media screen and (max-width: $breakpoint-mob-max) {
    top: auto;
    bottom: 100%;
    left: 5px;
    right: 5px;
  }
}

@import "./../../../../assets/css/variables.scss";

.switchSizeWrap {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.switchSizeLabel {
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  color: #000000;
  transform: rotate(0.18deg);
  margin-right: 10px;
  @media screen and (max-width: $breakpoint-mob-max) {
    font-size: 11px;
  }
}
.switchSize {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 5px;
}
.switchSizeValueLeft,
.switchSizeValueRight {
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 12px;
  color: #000000;
}
@import "./../../../assets/css/variables.scss";

.modalContinue2D {
  position: relative;
  width: 100%;
  max-width: 944px;
  padding: 63px 135px;
  background-color: #fff;
  @media screen and (max-width: $breakpoint-mob-max) {
    max-width: 600px;
    padding: 20px 25px;
  }
  &::before,
  &::after {
    content: "";
    position: absolute;
    top: 0;
    width: 30px;
    height: 100%;
    background-image: url("../../../assets/images/svg/warning-pattenr.svg");
    background-repeat: repeat-y;
    background-size: 100% auto;
    @media screen and (max-width: $breakpoint-mob-max) {
      width: 15px;
    }
  }
  &::before {
    left: 0;
  }
  &::after {
    right: 0;
  }
  .title {
    margin: 0 0 32px 0;
    font-weight: 700;
    font-size: 32px;
    line-height: 1.2;
    color: #000000;
    text-align: center;
    @media screen and (max-width: $breakpoint-mob-max) {
      margin-bottom: 20px;
      font-size: 18px;
    }
  }
  .noteText {
    margin: 0 0 32px 0;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    @media screen and (max-width: $breakpoint-mob-max) {
      font-size: 14px;
    }
  }
  .btnsWrap {
    display: flex;
    align-items: flex-start;
    gap: 16px;
    width: 540px;
    margin:  0 auto;
    @media screen and (max-width: $breakpoint-mob-max) {
      width: 100%;
      button {
        padding-left: 10px;
        padding-right: 10px;
      }
    }
    .col {
      width: 50%;
    }
  }
}
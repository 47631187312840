.styleGroup {
  margin-bottom: 30px;
  // padding: 0px 0px 16px;
  padding: 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.styleGroupTitle {
  margin:  0 0 30px 0;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: #000000;

  display: flex;
  align-items: center;
  span {
    margin-left: 17px;
    margin-bottom: 3px;
    cursor: pointer;
    svg {
      display: block;
    }
  }
}
@import "./../../assets/css/variables.scss";

.desktopSectionsParams {
  position: absolute;
  z-index: 100;
  width: 437px;
  left: 100%;
  top: 0;
  bottom: 0;
  background: #ffffff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  @media screen and (max-width: $breakpoint-mob-max) {
    position: relative;
    width: 100%;
    height: 35vh;
    left: auto;
    top: auto;
    bottom: auto;
    box-shadow: none;
  }
}

@import "./../../../../assets/css/variables.scss";

.btnsWrap {
  margin-bottom: 30px;
  .btnsTwo {
    margin-bottom: 32px;
    display: flex;
    gap: 16px;
    > * {
      width: 50%;
      &:first-child {
        flex: 1 0;
      }
    }
    [class*="btn"] {
      @media screen and (max-width: $breakpoint-mob-max) {
        padding-left: 10px;
        padding-right: 10px;
      }
    }
  }
}

.nameProjectInputWrap {
  margin-bottom: 5px;
  // input {
  //   &.error {
  //     border-color: #f32a2a;
  //     outline: #f32a2a auto 1px;
  //     &:focus-visible {
  //       outline: #f32a2a auto 1px;
  //     }
  //   }
  // }
}

.nameProjectInputWrap,
.emailQuoteWrap {
  input {
    width: 100%;
    min-width: auto;
    padding: 8px 16px;
    height: 33px;
    font-size: 14px;
    line-height: 17px;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    color: #000000;
    background: #FFFFFF;
    border: 1px solid var(--brand-color);
    border-radius: 2px;
    &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
      color: var(--brand-color);
      text-align: center;
    }
    &::-moz-placeholder { /* Firefox 19+ */
      color: var(--brand-color);
      text-align: center;
    }
    &:-ms-input-placeholder { /* IE 10+ */
      color: var(--brand-color);
      text-align: center;
    }
    &:-moz-placeholder { /* Firefox 18- */
      color: var(--brand-color);
      text-align: center;
    }
    &:focus-visible {
      outline: var(--brand-color) auto 1px;
    }
    &.error {
      border-color: #f32a2a;
      outline: #f32a2a auto 1px;
      &:focus-visible {
        outline: #f32a2a auto 1px;
      }
    }
  }
}

//.copiedLinkWrap,
.emailQuoteWrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 100%;
  margin-bottom: 32px;
  > * {
    &:first-child {
      flex: 1 0;
    }
    &:last-child {
      flex: 0 0;
      flex-basis: 110px;
      width: 110px;
      min-width: 110px;
    }
  }
  input {
    // width: auto;
    // min-width: auto;
    // padding: 8px 16px;
    // height: 33px;
    // font-size: 14px;
    // line-height: 17px;
    // font-weight: 700;
    // font-size: 14px;
    // line-height: 17px;
    // color: #000000;
    // background: #FFFFFF;
    // border: 1px solid #332E20;
    border-radius: 2px 0 0 2px;
    border-right: none;
    text-overflow: ellipsis;
    max-width: calc(100% - 110px);
    @media screen and (max-width: $breakpoint-mob-max) {
      width: 100%;
      max-width: none;
    }
    // &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    //   color: rgba(0, 0, 0, 0.5);
    //   text-align: center;
    // }
    // &::-moz-placeholder { /* Firefox 19+ */
    //   color: rgba(0, 0, 0, 0.5);
    //   text-align: center;
    // }
    // &:-ms-input-placeholder { /* IE 10+ */
    //   color: rgba(0, 0, 0, 0.5);
    //   text-align: center;
    // }
    // &:-moz-placeholder { /* Firefox 18- */
    //   color: rgba(0, 0, 0, 0.5);
    //   text-align: center;
    // }
  }
  .emailQuoteBtnWrap {
    position: relative;
    display: block;
    [class*="btn"] {
      width: 100%;
      padding-left: 10px;
      padding-right: 10px;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
    .loading {
      position: absolute;
      top: 50%;
      right: 0;
      width: 100%;
      transform: translateY(-50%);
      font-size: 0;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        height: 100%;
      }
    }
  }
}

.emailQuoteWrap {
  margin-bottom: 30px;
  @media screen and (max-height: 750px) {
    margin-bottom: 20px;
  }
}

.copiedLinkWrap {
  position: relative;
  margin-bottom: 0;
  .copiedResponce {
    margin: 0;
    position: absolute;
    top: calc(100% + 5px);
    left: 0;
    font-weight: 300;
    font-size: 12px;
    line-height: 17px;
    color: #000000;
    white-space: nowrap;
  }
}

.btnLoadingWrap {
  position: relative;
  .loading {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    font-size: 0;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      width: 100%;
    }
  }
}